export const name = 'FAQ'
export const order = 4;

# Frequently Asked Questions

## The whitelabel content is not rendered or remains empty.

In most cases, the cause is indicated by errors and messages in the browser console.

In the case of a **CORS error**: The whitelabel solution is only delivered to domains that are approved by Carmarket and configured for the CarmarketFrame ID. The official website of the dealer is usually used for the configuration. Please contact customer service if you want to add a domain specifically for development.

## Is it possible to predefine a filter in the full whitelabel solution?

This is not possible directly via the configuration.  However, filters can be transferred via a hash route in the higher-level URL. For example, the hash route `#/?vehicleCondition=1&transmission=2` sets a filter for all new cars with automatic transmission. The filter values for the hash route can be easily determined by filtering manually.

---

## The question is not listed here

Please contact our customer service. The contact details can be found on the page [SLA, Impressum](#/Sla "SLA, Impressum"). The following information is helpful for every customer service inquiry:

* Domain and URL of the page where the whitelabel solution is installed
* Messages in the browser console
* Browser used and the tested environment (desktop or mobile).
