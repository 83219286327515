export const name = 'Getting Started'
export const order = 0;

# Getting Started

This document describes the integration of Carmarket as a whitelabel solution into another website (typically of a dealer). For technical correctness and readability for web developers, this document is in English only.

*DE: Dieses Dokument beschreibt die Integration von Carmarket als Whitelabel-Lösung in eine andere Website (typischerweise eines Händlers). Für die technische Korrektheit und Lesbarkeit für Web-Entwickler ist dieses Dokument ausschliesslich in Englisch gehalten.*

*FR: Ce document décrit l'intégration de Carmarket dans un autre site web (généralement celui d'un concessionnaire). Pour des raisons d'exactitude technique et de lisibilité pour les développeurs web, ce document est rédigé en anglais uniquement.*

*IT: Questo documento descrive l'integrazione di Carmarket in un altro sito web (tipicamente di un rivenditore). Per correttezza tecnica e leggibilità per gli sviluppatori web, questo documento è solo in inglese.*

## Application Description

The CarmarketFrame (**Carmarket whitelabel solution for iframe embeds**) lets you embed the search and detail pages of your offered vehicles. The embedded application can be color styled according to your needs. Moreover, there are many settings to configure the search for vehicles according to the offering. The advanced functionalities of the embedded application allow direct links to embedded vehicles. In addition, all lead processes can be started directly.

## Minimal Example

```html
<div id="carmarket-frame"></div>
<script>
  window.carmarketFrameConfiguration = {
      selector: '#carmarket-frame', // Selector of the element to replace
      id: '###frameId###', // CarmarketFrame ID provided by Carmarket
      language: 'de', // Language of the application
      dealerIds: [###dealerIds###], // Dealer IDs provided by Carmarket
  }
</script>
<script type="module" src="https://whitelabel.carmarket.ch/v1/carmarket.js"></script>
```

## Steps to embed on a page

1. Add the **HTML Element** `<div id="carmarket-frame"></div>`

2. Add the **Script Elements** to the page.

    1. Script element with the configuration settings. See chapter "Configuration" for a full list of available settings.
    2. Script element with the library `carmarket.js`

3. If Content Security Policy (**CSP**) is enabled, add the domain `*.carmarket.ch` to the CSP of the page (see https://developer.mozilla.org/en-US/docs/Web/HTTP/CSP for more info).

```text
Content-Security-Policy: frame-src *.carmarket.ch; script-src *.carmarket.ch
   ```

4. If a sticky header is present, make sure to set `scroll-margin-top` via CSS to the Carmarket-frame inner iframe (see https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-margin-top for more info)
```css
#carmarket-frame iframe {
    scroll-margin-top: 200px; /* choose height of page header if present */
}
   ```

5. Enhance the informations about Carmarket in the **Privacy Policy** of your website.
